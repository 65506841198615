<template>
  <div>
    <tab-bar-wrapper @newRow="createQuote"></tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :query="query"
        :filter="filter"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :is-row-master-function="rowMasterFunction"
        :componentName="$options.name"
        :nested-grid="true"
        :columns="columns"
        :nestedColumns="nestedColumns"
        :nestedContextMenuItems="nestedContextMenuItems"
        @ready="ready"
        @nestedReady="nestedReady"
        @setComments="setComments"
        @setMasterObject="setCurrentOrderObject"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="saveComments"
    />
    <v-dialog
      lazy
      width="80%"
      v-model="showDialog"
      persistent
    >
      <add-quote-parts
        v-if="dialog.component === 'addToQuote'"
        :quote="quoteId"
        @save="createQuoteParts"
        @cancel="cancelAdd"
      />
      <pdf-viewer
        v-if="dialog.component === 'pdf'"
        :data="pdfData"
        :custom="pdfRules"
        datatype="quote"
        @close="$store.dispatch('grid/resetDialog')"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import helpers from '@/api/helpers'
import grid from '../../components/mixins/grid'
import addToQuote from '../../components/dialogs/addToQuote'
import columns from '../../components/mixins/columns'
import mutateQuotes from '@/api/graphql/mutationsJS/mutateQuotes'
import {
  GET_QUOTE__PDF,
  GET_QUOTE_PARTS_FOR_QUOTE,
  GET_QUOTES,
  GRID_GET_QUOTE_PARTS_FOR_QUOTE
} from '@/api/graphql/Constants/Quotes'
import { COLUMNS__QUOTES, COLUMNS__QUOTES_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__QUOTES, CONTEXT_MENU__QUOTES_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__QUOTES, KB_SHORTCUT__QUOTES_NESTED } from '@/lib/agGridKbShortCuts'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import ViewPDF from '@/components/dialogs/ViewPDF'
import { prepPdfData } from '@/lib/PdfPrep'
import { OrderEvents } from '../../store/orderEvents'
export default {
  name: 'quotes',
  mixins: [grid, columns, mutateQuotes],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-quote-parts': addToQuote,
    'pdf-viewer': ViewPDF
  },
  computed: {
    showDialog () {
      return this.dialog.app === 'quotes'
    }
  },
  data () {
    return {
      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'contract_comment': {
          name: 'contract_comment',
          label: 'Contract',
          text: '',
          editable: true,
          show: true
        },
        'id': false
      },
      query: GET_QUOTES,
      filter: [
        {
          key: 'type',
          value: 'ST'
        }
      ],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'parts'
        }
      ],

      nestedQuery: GRID_GET_QUOTE_PARTS_FOR_QUOTE,
      nestedFilters: id => [{ key: 'quote__id', value: id }],
      nestedAnnotations: [],

      contextMenuItems: (params) => CONTEXT_MENU__QUOTES(params, this.contextMenuCallback),

      nestedContextMenuItems: (params) => CONTEXT_MENU__QUOTES_NESTED(params),

      columns: COLUMNS__QUOTES,
      nestedColumns: COLUMNS__QUOTES_NESTED,
      quoteId: null,
      pdfData: {},
      pdfRules: []
    }
  },
  methods: {
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar',
      hideSnackbar: 'hideSnackbar'
    }),

    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    contextMenuCallback (params) {
      if (params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__QUOTES(this.contextMenuCallback), KB_SHORTCUT__QUOTES_NESTED())
    },

    resetEditables () {
      this.gridMode = 'read'
      this.editableNodes = []
      this.editedData = []
    },

    save () {
      helpers.save(this.gridMode, this.editedData, this.query)
      this.resetEditables()
    },

    cancel () {
      if (this.gridMode !== 'read') {
        this.resetEditables()
      }
    },

    createQuote () {
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newQuote' })
    },

    async createQuoteParts (parts) {
      this.saving = true
      const createdParts = []
      let total = this.quoteTotal
      try {
        for (const part of parts) {
          const createdPart = await this.createQuotePart(part)
          total = total + part.total
          createdParts.push(createdPart)
        }
        await this.updateQuote({ id: this.quoteId, total_price: total })
        if (this.nestedGapi !== null) this.nestedGapi.updateRowData({ add: createdParts })
        this.saving = false
        this.$store.dispatch('notifications/addSuccessAlert', { message: 'Added part successfully.' })
      } catch (error) {
        this.$store.dispatch('notifications/addErrorAlert', { message: error })
        this.saving = false
      }
    },

    addParts (data) {
      this.quoteId = data.id
      this.quoteTotal = data.total
      this.$store.dispatch('grid/changeDialog', { app: 'quotes', component: 'addToQuote' })
    },

    async convertToSale ({ params }) {
      // need client, contact if available
      // contract comment
      // internal comment
      // parts
      this.createSnackbar({
        message: 'Loading...',
        color: 'info'
      })
      const quote = params.node.data

      const quoteId = quote.id
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newSale' })
      setTimeout(() => OrderEvents.$emit('send-quote-id', { value: quoteId }), 500) // emits to newSale.vue
    },

    async viewPdf ({ id }) {
      this.createSnackbar({
        message: 'Getting data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_QUOTE__PDF,
          variables: { id: id }
        })
        if (response.data.quotes_quote) {
          const prepped = prepPdfData.Quote(response.data.quotes_quote)
          this.pdfData = prepped.pdf
          this.custom = prepped.rules
          this.$store.dispatch('grid/changeDialog', { app: 'quotes', component: 'pdf' })
          this.hideSnackbar()
        }
      } catch (e) {
        const message = e.message || 'Could not get data for quote'
        this.createSnackbar({
          message: message,
          color: 'error',
          timeout: 4000
        })
      }
    },

    cancelAdd () {
      this.quoteId = 0
      this.quoteTotal = 0
    }
  }
}
</script>
