<template>
  <v-card>
    <v-card-title class="light-blue"><h3 class="white--text">Add Parts to Quote: {{ quote }}</h3></v-card-title>
    <v-card-text>
      <add-parts-template
        :parts="parts"
        order="quote"
      />
    </v-card-text>
    <v-card-actions>
      <t-alert :message="message" :type="type"/>
      <v-spacer></v-spacer>
      <cancel-button class="pr-2" :success="succeeded" @click="cancel"/>
      <submit-button :disabled="parts.length === 0 || succeeded" :loading="saving" @click="save"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import commonAutofillFilters from '../mixins/commonAutofillFilters'
import moneyFormatter from '../mixins/moneyFormatter'
import addPartsTemplate from '@/components/templates/addPartsTemplate'
import tAlert from '@/components/notifications/tAlert'
import { CREATE_QUOTE_PARTS } from '@/api/graphql/Constants/Quotes'
import { groupLikeItemsWithGivenKeys } from '@/lib/arrayHelpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'addToQuote',
  mixins: [commonAutofillFilters, moneyFormatter],
  components: {
    'add-parts-template': addPartsTemplate,
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    quote: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      message: '',
      type: 'info',
      succeeded: false,
      saving: false,

      parts: []
    }
  },
  methods: {
    save () {
      this.saving = true
      const partsToQuote = []
      const partsToMake = groupLikeItemsWithGivenKeys(this.parts, ['id', 'soldFor'])

      for (const part of partsToMake) {
        const partToQuote = {
          part_id: part.id,
          price_per_part: part.soldFor,
          qty: part.quantity,
          quote_id: this.quote
        }
        partsToQuote.push(partToQuote)
      }

      this.$apollo.mutate({
        mutation: CREATE_QUOTE_PARTS,
        variables: { items: partsToQuote }
      }).then(() => {
        this.message = 'Added parts to quote'
        this.type = 'success'
        this.succeeded = true
      }).catch(() => {
        this.message = 'Could not add parts to quote. Try again or contact support.'
        this.type = 'error'
      }).finally(() => {
        this.saving = false
      })
    },

    cancel () {
      this.$store.dispatch('grid/resetDialog')
      this.$emit('cancel')
    }
  }
}
</script>
